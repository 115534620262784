main {
  background-color: $bg-white;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.l-container {
  max-width: $g-max-width;
  margin: 0 auto;
}

.l-container--narrow {
  max-width: $g-max-width-narrow;
}

.l-50-50 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.l-40-60 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
}

.l-33-33-33 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.scroll-y-container {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  
  height: 100%;
  padding-top: $navigation-height;

  @include screen--max(340) {
    padding-top: $navigation-height-small;
  }
}

.body--portrait {
  padding: env(safe-area-inset-top) 0 0 0;
}
