.complexfield__body {
  position: relative;
}

.complexfield {
  @extend .input;

  font-size: $font--size--3;

  display: block;
  margin: 0 0 $field-margin-simple 0;
  background-color: $bg-alt-1;
  width: 100%;

  padding: 0 $icon-spacing--custom;

  &#{&}--labeled {
    padding-left: $g-spacing-regular * 2;
  }

  &#{&}--linked {
    padding-right: $interaction-element-height + $g-spacing-regular;
  }

  &#{&}--hidden {
    display: none;
  }
}

.complexfield__textarea {
  min-height: $interaction-element-height * 1.5;
  height: auto;
  padding: $icon-spacing--custom;
  line-height: normal;
}

.complexfield__label {
  display: inline;
  position: absolute;
  padding: $icon-spacing--custom;
  width: auto;
  background-color: transparent !important;
  margin: 0;
}

.complexfield__url {
  align-items: center;
  display: flex;
}

.complexfield__cta {

  display: inline;
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  width: $interaction-element-height;
  height: $interaction-element-height;
  pointer-events: auto;

  &:after {
    @include background-2x('../img/UI.iconset/medium/select', 'png', 100%);
    transform: rotate(-90deg);

    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: $interaction-element-height;
    height: $interaction-element-height;
  }
  
  &--small {
    height: $interaction-element-height / 2;
    
    &:after {
      height: $interaction-element-height / 2;
    }
  }
}

.complexfield__cta--expandable {
  display: inline !important;
  height: $interaction-element-height / 2;
  box-shadow: $shadow-regular;
  border-radius: $radius-sml;

  &:after {
    @include background-2x('../img/UI.iconset/medium/expand', 'png', 100%);
    transform: rotate(0deg);
  }
}

/* Naming madness: The expandableSection's CTA uses the same button element like complexFields.
   But the click-sensitive area also includes the adjacent separator. */
.complexfield__cta--expanded {  // the CTA's click element
    
    .complexfield__cta--expandable {    // the CTA element (button)
        &:after {
            @include background-2x('../img/UI.iconset/medium/collapse', 'png', 100%);
        }
    }
}

.complexfield__cta--immutable {
    pointer-events: none;
    text-decoration: none;
    
    .complexfield__cta--expandable {
        opacity: 0.5;
        box-shadow: none;
    }
}

.complexfield__cta__signature {
  display: inline !important;

  &:after {
    @include background-2x('../img/UI.iconset/medium/select_pen', 'png', 100%);
    transform: rotate(0deg);
  }
}

.complexfield__cta__scanner {
  display: inline;

  &:after {
    @include background-2x('../img/UI.iconset/medium/codescan', 'png', 100%);
    transform: rotate(0deg);
  }
}

.complexfield__cta__scanner.nfc {
  display: inline;

  &:after {
    @include background-2x('../img/UI.iconset/medium/nfc', 'png', 100%);
    transform: rotate(0deg);
  }
}

/* complexfield_select_label has been defined in _select.scss to not get overwritten :( */
