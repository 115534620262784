@mixin background-2x($path, $ext: "png", $size: contain, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: $size;
  background-position: $pos;
  background-repeat: $repeat;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {
    background-image: url("#{$at2x_path}");
  }
}