.dropzone {
  border: 0 none;
  min-height: 0;
  box-shadow: $shadow-regular;

  &#{&}--hidden {
    display: none;
  }

  .assetField__content {
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '+';
      pointer-events: none;
      position: absolute;

      color: $white;
      background-color: $blue;

      text-align: center;
      font-size: $interaction-element-height - $asset-border--width * 2;
      line-height: $interaction-element-height - $asset-border--width * 2;

      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dz-preview {
    margin: 0;
    position: absolute;

    min-height: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .dz-details {
      padding: 0;
      opacity: 1;
    }

    .dz-filename,
    .dz-size,
    .dz-progress {
      display: none;
    }
  }
}
