
.select {

  @extend .button;

  box-shadow: none; // in order to expand the shadow, it must be added to the select_body

  $lighten-percent: 10%;

  position: relative;
  padding: 0;
  background-color: transparent;
  color: $black;
  height: auto;
  min-width: 168px;
  max-width: 50vw;

  &:first-child {
    margin-top: inherit;
  }

  &:active {
    background-color: lighten($white, $lighten-percent);
  }

  &#{&}--hidden {
    display: none;
  }
}

.select__dropdown {
  display: none;

  &#{&}--inline {
    background-color: $green;
  }

  &.select__dropdown--on:checked {

    & ~ .select__label--on {
      display: none;
      z-index: 4;
    }

    & ~ .select__label--off {
      display: block;
      z-index: 5;
    }

    & ~ .select__body {
      max-height: 250px;
      width: 100%;
      overflow-y: scroll;
      z-index: 3;

      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;

      -moz-flex-flow: wrap;
      -webkit-flex-flow: wrap;
      flex-flow: wrap;

      box-shadow: $shadow-regular;

      .select__option {
        display: block;
        width: 100%;
        order: 3;
		  
        img[src=''] {
            display: none;
        }

        &--selected {
          font-weight: bold;
          order: 2;
        }

        &.liveselect {
          order: 1;
        }
      }

      .select__title {
        order: 1;
        font-weight: normal;
      }

      @at-root {
        .select__title > span:empty {
          @at-root {
            .select__option.select__title {
              height: auto;
            }
          }
        }
      }
    }
  }

  &.select__dropdown--off:checked {
    & ~ .select__label--on {
      display: block;
      z-index: 5;
    }

    & ~ .select__label--off {
      display: none;
      z-index: 4;
    }
  }
}

.select--readonly > .select__label {
  display: none;
}

.select__label {

  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  float: right;
  border-radius: $radius-sml;
  line-height: $interaction-element-height / 2;
  padding: $g-spacing-smaller $interaction-element-height $g-spacing-smaller $g-spacing-medium;

  z-index: 2;

  &:after {
    @include background-2x('../img/UI.iconset/medium/select', 'png', 100%);

    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: $interaction-element-height;
    height: $interaction-element-height;
  }

  &#{&}--off {
    display: none;
    width: 40px;
    float: right;

    &:after {
      transform: rotate(180deg);
    }
  }
}


.select__body {

  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  background-color: $white;
  border-radius: $radius-sml;
  box-shadow: $shadow-regular;

  clear: both;
  max-height: $interaction-element-height;
  transition: max-height .2s ease-out;

  z-index: 1;

  &#{&}--inline {
    background-color: $alt-2;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

.select__option {
  margin: 0 $g-spacing-medium;
  border-bottom: 1px solid $alt-2;

  vertical-align: middle;
  line-height: $interaction-element-height;

  display: none;

  &:first-child {
    margin-left: $g-spacing-medium;
  }

  &:first-child,
  &:last-child {
    border: 0;
  }

  &#{&}--readonly {
    pointer-events: none;
    border: 0;
  }

  &#{&}--selected {
    display: block;
	  
    img[src=''] {
        display: none;
    }
  }
}

.liveselect {
  &.select__option {
    margin: 0;
  }

  .select__option-title {
    margin-left: $g-spacing-medium;
    display: none;

    &.liveselect__search--hidden {
      display: block;
    }
  }

  .select__option-input {
    &.liveselect__search--hidden {
      display: none;
    }
  }
}

.select__option-input {
  height: 35px;
}

// ========= Custom styles for select fields within the detail view ===========

.complexfield__text {
  .select__option-input & {
    height: 38px;
    width: calc(100% - #{$interaction-element-height});
    padding: 0 12px;
    margin: 0;
    box-shadow: none !important;
  }
}
//width: calc(100% - 33px);

.complexfield__select {
  width: 100%;
  max-width: 100%;
  height: $interaction-element-height;

  background-color: #fff;
  color: #000;
  border-radius: 2px;
  box-shadow: none;

  .select__body {
    box-shadow: none;
  }

  &#{&}--readonly {
    pointer-events: none;
  }
}

.complexfield__select__body {

  margin: 0;
  padding: 0;
  width: 100%;

  transition: max-height .2s ease-out;


  &#{&}--readonly {
    pointer-events: none;
  }
}
