// -- shame -----------------------------
$footer-height: 5vh;
$interaction-element-height: 4em;
$interaction-element-height-small: $interaction-element-height * 0.75;
$highlight-border-width: 0.14em;

$height-default: 3em;

// -- TOM brand colors -----------------
$green: rgb(75, 130, 25);   //#4B8219
$light-green: rgba(205, 221, 185, 1);
$red: rgb(225, 50, 50); //#E13232
$blue: rgba(39, 128, 200, 1); //#288CC8
$light-blue: transparentize($blue, 0.76);
$lighter-blue: transparentize($blue, 0.82);
$darkblue: rgba(25, 45, 75, 1);
$tomblue: rgba(62, 69, 101, 1);
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$gray: rgba(69, 69, 69, 1);
$light-gray: transparentize($gray, 0.2);
$lighter-gray: transparentize($gray, 0.75);


// -- Fonts ---------------------------
$body-font: "Helvetica Neue", Helvetica, Arial, sans-serif;

// -- UI colors ---------------------------
$color-success: #5CB85C;
$color-info: #5BC0DE;
$color-warning: #F0AD4E;
$color-error: $red; //#D9534F;


// -- spacing -----------------------------
$spacing-xs: 0.33em;
$spacing-s: 0.67em;
$spacing-default: 1em;
$spacing-l: 1.33em;
$spacing-xl: $spacing-l * 2;
$spacing-xxl: $spacing-xl * 2;


// -- breakpoints -------------------------
$breakpoints: (
    tablet: 340px
);


// -- shadows -----------------------------
$shadow-light: 0 1px 3px 0 rgba($black, .15);
$shadow-regular: 0 1px 3px 0 rgba($black, .45);
$shadow-dark: 0 1px 3px 0 rgba($black, .76);
$shadow-inset-light: inset 0 -3px 3px rgba($black, .12);


// -- transition timing -------------------
$transition-fast: .137s;
$transition-normal: .277s;

// -- font size --
$font-size--1: 1.67em;
$font-size--2: 1.3em;
$font-size--3: 1.1em;
$font-size--4: 1em;
$font-size--5: .83em;
$font-size--6: .67em;
$font-size--7: 1em;

$line-height--medium: 1.2em;


// -- icons --------------------------------
$icon-size--default: 1em;
$icon-size--m: 1.2em;
$icon-size--l: 1.5em;
$icon-size--xl: 2em;

$icon-spacing--default: $icon-size--default / 2;
$icon-spacing--l: $icon-size--l / 2;
$icon-spacing--xl: $icon-size--xl / 2;


// -- borders -------------------------------
$border-radius--default: 0.14em;


// -- duplicate defaults that are used by zoom-limited components ---
$rem-interaction-element-height: 4rem;
$rem-height-default: 3rem;

$rem-spacing-xs: 0.33rem;
$rem-spacing-default: 1rem;
$rem-spacing-l: 1.33rem;

$rem-font-size--2: 1.3rem;

$rem-icon-size--default: 1rem;
$rem-icon-size--xl: 2rem;

$rem-icon-spacing--default: $rem-icon-size--default / 2;
$rem-icon-spacing--xl: $rem-icon-size--xl / 2;

$rem-border-radius--default: 0.14rem;
