body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;

  content:
    url(../img/UI.iconset/small/confirm@2x.png)
    url(../img/UI.iconset/small/editissue@2x.png)
    url(../img/UI.iconset/medium/select@2x.png);
}