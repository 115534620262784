
$smart-action-size: 80px;
$smart-action-size-m: 60px;
$smart-action-size-s: 40px;

.issue-list__body {

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &--active {
    left: -100%;
  }
}

.issue-list__container {
  list-style: none;

  display: flex;
  flex-direction: column;

  z-index: 1;
}

.issue {

  $icon-width: $g-spacing-medium;

  position: relative;
  margin-bottom: 1px;
  order: 2;
  z-index: 1;

  &--pinned {
    order: 1;
    z-index: 2;

    .issue__description:after {
      content: '';
      position: absolute;
      top: $g-spacing-small;
      right: $g-spacing-regular;
      width: $icon-width;
      height: $icon-width;
      @include background-2x('../img/UI.iconset/medium/pushpin', 'png', contain, 100%);
    }
  }

  [class*="-icon"] img {
    width: $icon-width;
  }
}

/* doesn't seem to work */
[class$='issue--pinned']:last-of-type {
  box-shadow: $shadow-dark;
}

.issue__title-icon {
  width: $icon--tiny;
  height: $icon--tiny;
  margin-right: $icon-spacing--custom;
  margin-bottom: $icon-spacing--tiny * 1/2;
  vertical-align: middle;

  &#{&}--input {
    margin-right: 0;
  }
}

.issue__description {

  padding: ($g-spacing-smaller + 1) 0;
  background-color: $white;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;

  &--animate {
    transition: left $g-animation-time-fast;
  }

  > * {
    margin: 0 $g-spacing-regular;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
}

.issue__actions {
  height: $smart-action-size;
  list-style: none;
}

.issue__action {

  $lighten-percent: 10%;

  width: $smart-action-size;
  height: $smart-action-size;
  padding: $g-spacing-smaller;

  float: left;

  line-height: 1em;
  text-align: center;
  font-size: $font--size--5;
  text-overflow: ellipsis;
  overflow: hidden;

  border: 1px;
  border-color: $alt-2; // same as background, see .issue-list__body
  border-style: solid none;

  img {
    width: $icon--tiny;
    height: $icon--tiny;
    margin: $g-spacing-small auto;
    display: block;
  }

  &:active {
    cursor: pointer;
    background-color: lighten($g-color-base, $lighten-percent);
  }

  &#{&}--hidden {
    display: none;
  }

  &#{&}--right {
    float: right;
  }

  &#{&}--green {
    background-color: $green;

    &:active {
      background-color: lighten($green, $lighten-percent);
    }
  }

  &#{&}--blue {
    background-color: $blue;

    &:active {
      background-color: lighten($blue, $lighten-percent);
    }
  }
}

.issue--size-2 {
  .issue__actions {
    height: $smart-action-size-m;
  }
  .issue__action {
    height: $smart-action-size-m;

    img {
      margin-top: $icon-spacing--tiny * 1/2;
      margin-bottom: $icon-spacing--tiny;
    }
  }
}

.issue--size-1 {
  .issue__actions {
    height: $smart-action-size-s;
  }
  .issue__action {
    height: $smart-action-size-s;
    line-height: $smart-action-size-s * 1/2;

    img {
      display: none;
    }
  }
}
