$background-color: $white;

html {
  min-height: 100%;
}

body {
  background-color: $background-color;
  color: choose-contrast-color($background-color);

  font: 100%/1.5 $g-body-font;
  font-size: $doc-font-size;
  -webkit-text-size-adjust: 100%;
  display: block;
  flex-direction: column;
  min-height: 100%;

  border-top: 1px solid $alt-2;
}
