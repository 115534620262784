.assetField {
  position: relative;
  width: $interaction-element-height;
  height: $interaction-element-height;
  float: left;
  margin: 0 $field-margin-simple $field-margin-simple 0;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

}

.assetField__content {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &#{&}--highlighted {
    border: $asset-border--width solid transparent;
  }
}

.assetField__preview {
  pointer-events: none;
  display: none;
}
