.issue-detail__body {

  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;

  transition: $container-slide-transition-fast;

  &--active {
    left: 0;
  }
}

.issue-detail__help {
  position: absolute;
  top: 0;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
}

.issue-detail__container {
  @extend .issue-detail__help;
}

.issue-detail__form {
  padding: $g-spacing-regular;
  padding-top: $navigation-height + $g-spacing-regular;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.issue-detail__fields {
  @extend .issue-list__container;

  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  display: contents; // children should align dimensions to the parent form element
  z-index: auto;
  
  &--expandable {
      position: relative;
      overflow: hidden;
      height: 0;
      margin-left: 1px; // indentation to vertically align with the separator label
      padding-left: $g-spacing-small;
      transition: height 0.2s ease-out;
      
      &:before {
          border-left: 1px solid $alt-1;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: calc(100% - 5px); // minus field-margin-simple to not exceed the last nested cF
      }
  }

  input, textarea,
  select, .select__body {
    background-color: $bg-alt-2;
  }

  .select--readonly {
    pointer-events: none;
  }

  &[disabled] *:not(.complexfield__textarea),
  &[disabled] *:not(.assetField) {

    /* hide dropdown arrow from select fields */
    .select__label {
      &:after {
        background: none;
      }
    }
  }

  &:not([disabled]) {

    /* iOS: The readonly attribute is supported with textarea only. */
    input:disabled, input[readonly] {
      pointer-events: none;
    }
  }

  .complexfield__cta {
    display: none;
  }

  .complexfield__cta__scanner {
    display: inline;
  }

  .complexfield--linked {
    padding-right: $g-spacing-regular;
  }

  .complexfield__text--label {
    display: none;
  }

  /* The field can be either readonly or not (and must not be greyed out when set to readonly) */
  .complexfield__text--preview {
    pointer-events: none;
  }

  &[disabled] {
    .complexfield__cta {
      display: inline;
      pointer-events: auto;
    }

    .complexfield--linked {
      padding-right: $interaction-element-height + $g-spacing-regular;
    }

    .complexfield__text--label {
      display: block;
    }

    .complexfield__text--value {
      display: none;
    }
  }

  &:not([disabled]) {
    input, textarea,
    select, .select:not(.select--readonly) .select__body {
      &:not([readonly]) {
        background-color: $white;
        box-shadow: $shadow-regular;
      }
      &[required],
      &[class*="--required"] {
        border: 1px solid $blue;
      }
      &[class*="--error"] {
        border: 1px solid $red;
      }
    }

    input.complexfield--linked.complexfield__text--value[readonly] {
      & ~ .complexfield--linked.complexfield__text--label {
        display: block;
      }

      & ~ .complexfield__cta {
        display: inline;
        pointer-events: auto;
      }
    }

    input.complexfield--linked.complexfield__text--value[readonly] {
      display: none;
    }
  }
}

.complexField__signature {
	position: fixed; /* Sit on top of the page content */
	display: none; /* Hidden by default */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); /* Black background with opacity */
	z-index: 1015; /* Specify a stack order in case you're using a different order for other elements */
	
	.signature-pad {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100vmin;
		height: 75vmin;
		max-width: 700px;
		max-height: 460px;
		border: 1px solid #e8e8e8;
		background-color: #fff;
		border-radius: 4px;
	}
	
	.signature-pad--navigation {
		z-index: 11;
	}
	
	.signature-pad--body {
		position: relative;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border: 1px solid #f4f4f4;
	}
	
	.signature-pad--body canvas {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: #fff;
		
		&[class*="--error"] {
			border: 1px solid $red;
		}
	}
	
	.signature-pad .complexField {
		&[class*="--error"] {
			border: 1px solid $red;
		}
	}
	
	.description {
		position: absolute;
		bottom: 0;
		padding: 5px;
		color: #777;
		font-size: $font--size--4;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}
	
	.signature-pad--footer {
		color: #C3C3C3;
		font-size: $font--size--3;
		margin-top: 8px;
	}
	
	.signature-pad--actions {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-top: 8px;
		
		div {
			width: 100%;
			
			button {
				line-height: 55px;
				height: 55px;
				background-size: 26px;
				background-position: 50% 50%;
				background-color: white;
				box-shadow: none;
				border: 0;
			}
			
			button:disabled {
				opacity: 0.6;
			}
			
			button[data-action="clear"] {
				float: left;
				margin-left: -16px;
			}
			
			button[data-action="confirm"] {
				float: right;
				margin-top: 0;
				margin-right: -16px;
			}
		}
	}
}

.body--landscape {
  .complexField__signature .signature-pad {
    width: 133.3vmin;
    height: 100vmin;
  }
}

/* reset alignment back to default as iOS15
   centers the contents of date fields and
   colors them blue */
input::-webkit-date-and-time-value {
  text-align: initial;
  color: initial;
}
