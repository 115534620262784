@import "../svelte/style/variables";

main {
    background-color: transparent;
}

.complexfield {
	font-size: $font-size--4;
	height: 3em;
	line-height: normal;
	
	&.complexfield__textarea {
		height: auto;
		resize: none;
	}
}

.complexfield__cta,
.complexfield__cta:after,
.select__label:after {
	width: 3em;
	height: 3em;
}

.select__body {
	max-height: 3em;
	border: 1px solid transparent;
}

.select__option {
	line-height: 3em;
	height: 3em;
	
	&:not(:first-of-type) {
		padding: $spacing-s $spacing-xs;
		margin: 0 $spacing-s;
		line-height: $font-size--2;
		border-bottom: 1px solid $lighter-gray;
		height: initial;
	}
	
	&:last-of-type {
		border-bottom: none;
	}
	
	&.select__option--selected {
		border: none;
		
		padding-right: $spacing-default * 3;
		
		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	
	div {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		
		> * {
			display: inline-block;
			line-height: $icon-size--default * 1.2 + $icon-spacing--default * 0.5;
		}
		
		> .issue__title-icon {
			width: auto;
			height: $icon-size--default * 1.2;
			margin-right: $icon-spacing--default * 1.5;
			margin-bottom: $icon-spacing--default * 0.5;
			vertical-align: middle;
			
			&[src=''] {
				display: none;
			}
		}
	}
}

.select__dropdown {
	
	/* if expanded, the selected item returns to its initial position (order) */
	&.select__dropdown--on:checked {
		
		& ~ .select__body {
			
			.select__option {
				
				&:first-of-type {
					border-bottom: 1px solid $lighter-gray;
					
					/* The first option element has a top and a bottom border, each with a thickness of 1px.
					   In order to keep the bottom border within the desired element height of 3em,
					   we have to subtract the two borders. */
					.complexfield__text {
						height: calc(3em - 2px);
					}
				}
				
				/* enable word-wrap and replace v1 border settings (again) */
				&--selected {
					padding-right: initial;
					
					&:not(:first-of-type) {
						border-bottom: 1px solid $lighter-gray;
					}
					
					&:last-of-type {
						border-bottom: none;
					}
					
					p {
						overflow: initial;
						text-overflow: initial;
						white-space: initial;
					}
				}
				
				&--disabled {
					opacity: 0.7;
					color: $light-gray;
					pointer-events: none;
					backdrop-filter: blur(2px);
				}
			}
		}
	}
}

.complexfield__cta--expandable {
	height: 1.5em;
}

/* override _select.css, line 255 ff.*/
.complexfield__text {
	.select__option-input & {
		height: 3em;
	}
}

.issue__title-icon, .subnavigation__icon {
	width: 1.2em;
	height: auto;
	margin-right: 0.75em;
	margin-bottom: 0.25em;
	vertical-align: middle;
}

.issue-detail__fields {
	input,
	select, .select__body {
		line-height: 2.6em;
		text-align: left;
	}
}

.separator--with-button {
	/* keep a static distance to the next element, regardless of the font-size (rem) */
	min-height: 20px;
	margin-bottom: $icon-spacing--default * 0.75;
	
	&:before {
		margin-right: 3.3em;
		width: calc(100% - 3.3em);
	}
}
