* {
  box-sizing: border-box;
}

html, body, div, object, iframe, hr, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, fieldset, legend, label, table, header, footer, nav, section, figure {
  margin: 0;
  padding: 0;
}

header, footer, nav, section, article, hgroup, figure {
  display: block;
}

button,
input,
option, select, textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;

  -webkit-user-select: initial;
  font-family: $g-body-font;
}

fieldset,
textarea {
  border: 0;
}

input:matches([type="button"], [type="submit"], [type="reset"]):disabled,
input:matches([type="button"], [type="submit"], [type="reset"]):readonly,
input[type="file"]:disabled::-webkit-file-upload-button,
input[type="file"]:readonly::-webkit-file-upload-button,
button:readonly, select:readonly, keygen:readonly, optgroup:readonly, option:readonly, select[readonly] > option,
button:readonly, select:readonly, keygen:readonly, optgroup:disabled, option:readonly, select[readonly] > option {
  color: #000000;
}

input:disabled, textarea:disabled,
input[readonly], textarea[readonly] {
  color: #000000;
  cursor: default;
  opacity: 1;
}