
.navigation {
  $background-color: $white;

  position: absolute;
  width: 100%;
  background-color: $background-color;
  z-index: 11;

  body.body--modal--active & {
    z-index: 0
  }

  &__body {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    display: inline-block;
    position: relative;

    box-shadow: $shadow-regular;

    &#{&}--hidden {
      display: none;
    }
  }

  &#{&}--hidden {
    display: none;
  }
}

.navigation__item {
  margin: 0;
  padding: 0;
  float: left;
  vertical-align: middle;
  line-height: $navigation-height;
  height: $navigation-height;
  background: $white;

  @include screen--max(340) {
    line-height: $navigation-height-small;
    height: $navigation-height-small;

    .select label.select__label:after {
      width: $interaction-element-height-small;
      height: $interaction-element-height-small;
    }

    .select .select__option {
      line-height: $interaction-element-height-small;
      margin: -1px $icon-spacing--custom;
    }
  }

  .navigation__icon {
    float: left;
  }

  &#{&}--left {
    border-right: 1px solid $alt-2;
  }

  &#{&}--centered {
    clear: both;

    .navigation__icon {
      float: right;
    }
  }

  &#{&}--right {
    float: right;
    border-left: 1px solid $alt-2;

    .navigation__icon {
      float: right;
    }
  }

  &#{&}--absolute-right {
    position: absolute;
    border: none;
    right: 0;
    top: 0;
    z-index: 0;

    .navigation__icon {
      float: right;

    }
  }

  &#{&}--readonly {
    pointer-events: none;
    border: 0;
  }

  &#{&}--hidden {
    display: none;
  }

  &#{&}--inline {
    padding: (($navigation-height - $interaction-element-height) / 2) 0;
  }
}

.navigation--sub {
  position: relative;
  z-index: 10;

  top: $navigation-height;

  @include screen--max(340) {
    top: $navigation-height-small;
  }

  .navigation__item {
    margin: $g-spacing-smaller;
    margin-right: 0;

    box-shadow: $shadow-regular;

    &:first-child {
      margin-left: $g-spacing-medium;
    }

    &:last-child {
      margin-right: $g-spacing-medium;
    }

    &--absolute-right:last-child {
      margin-right: 0;
    }

    &:active {
      cursor: pointer;
      box-shadow: $shadow-light;
    }

    &--transparent {
      background: transparent;
      box-shadow: 0 0 0 0 rgba(0,0,0,0);

      &:active {
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
      }
    }

    &--no-vertical-spacing {
      margin: $g-spacing-smaller 0;
    }
  }
}

.subnavigation {

  background-color: $white;

  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  display: none;
  list-style: none;

  &#{&}--inline {
    background-color: $alt-2;
  }
}

.subnavigation__item {
  padding: $g-spacing-medium $g-spacing-regular;
  border-bottom: 1px solid $alt-2;
  background: white;
  line-height: $font--size--2;

  overflow: hidden;
  display: block;

  position: relative;

  &:last-child {
    border: 0;
    box-shadow: $shadow-regular;
  }

  &.logout {
    background-color: $red;
  }
}

.subnavigation__icon {

  @extend .issue__title-icon;

  &[src=''] {
    display: none;
  }
}

.notification {
  position: relative;
  z-index: 5;
  background: #ccc;
  top: $navigation-height;
  height: 0;
  //overflow: hidden;
  transition: height .2s;

  @include screen--max(340) {
    top: $navigation-height-small;
  }
}

.notification__item {
  @extend .subnavigation__item;

  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: $white;
  padding-right: 0;

  &:last-child {
    box-shadow: inset 0 -3px 3px rgba($black, .12);
  }
}

.notification__message {
    display: inline-block;
    width: 80%;
}

.notification__close {
    background: transparent;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    
    /* button should have a vertical centered position
       to cover single-line and multi-line notifications */
    position: absolute;
    margin: 0;
    margin-left: $g-spacing-smaller;
    top: 50% !important;
    transform: translateY(-50%);
}

.navigation__logo {

  position: relative;
  margin: 0 $g-spacing-tiny - 1;
  display: inline-flex;
  height: $navigation-height;

  @include screen--max(340) {
    height: $navigation-height-small;
  }

  > * {
    height: 30px;
    width: auto;
    vertical-align: middle;
    margin: auto 0;
  }
}

.navigation__icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
  width: $navigation-height;
  height: $navigation-height;
    
    &--doublesize {
        width: $navigation-height * 2;
    }

  @include screen--max(340) {
    padding: 25px 17px;
    width: $navigation-height-small;
    height: $navigation-height-small;
    
      &--doublesize {
          width: $navigation-height-small * 2;
      }
  }
  
  &#{&}--discard {
    background-size: 22px ! important;
  }

  &#{&}--image {
    padding: $g-spacing-medium;
    background-size: 26px;
    background-position: 50% 50%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &#{&}--large-image {
    background-size: 40px;
  }

  &#{&}--image-label {
    padding: $g-spacing-small $g-spacing-tiny;
    line-height: $font--size--5;
    text-align: center;
    font-size: $font--size--5;
    text-overflow: ellipsis;
    overflow: hidden;

    img {
      width: $icon--tiny;
      height: auto;
      margin: 0 auto;
      margin-bottom: $g-spacing-smaller;
      display: block;
    }
    
    
      @include screen--max(340) {
          padding: $g-spacing-smaller $g-spacing-tiny $g-spacing-small $g-spacing-tiny;
          
          img {
              width: $icon--tiny * 0.9;
          }
      }
  }

  &--quickscan {
    @include background-2x('../img/UI.iconset/medium/codescan', 'png', contain, 100%);
  }

  &--nfc {
    @include background-2x('../img/UI.iconset/medium/nfc', 'png', contain, 100%);
  }

  &--newissue {
    @include background-2x('../img/UI.iconset/medium/newissue', 'png', contain, 100%);
  }

  &--editissue {
    @include background-2x('../img/UI.iconset/medium/editissue', 'png', contain, 100%);
  }

  &--saveissue {
    @include background-2x('../img/UI.iconset/medium/confirm', 'png', contain, 100%);
  }

  &--closeissue {
    @include background-2x('../img/UI.iconset/medium/closeissue', 'png', contain, 100%);
  }

  &--close {
    @include background-2x('../img/UI.iconset/medium/close', 'png', contain, 100%);
  }

  &--search {
    @include background-2x('../img/UI.iconset/medium/search', 'png', contain, 100%);
  }

  &--home {
    @include background-2x('../img/UI.iconset/medium/home', 'png', contain, 100%);
  }

  &--discard {
    @include background-2x('../img/UI.iconset/medium/discard', 'png', contain, 100%);
  }

  .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;

    @include screen--max(340) {
      left: -2px;
      top: -3px;
    }

    &:before,
    &:after {
      background: #333;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .2s ease-out;
      width: 100%;
    }

    &:before {
      top: 5px;
    }

    &:after {
      top: -5px;
    }
  }
}

.navigation__dropdown {
  display: none;

  &:checked ~ .subnavigation {
    max-height: 240px;
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: $navigation-height;

    @include screen--max(340) {
      top: $navigation-height-small;
    }

    border-top: 1px solid $alt-2;

    &:before {
      content: ' ';
      border-top: 4px solid $white;
      position: absolute;
      top: -2px;
      width: $navigation-height;

      @include screen--max(340) {
        width: $navigation-height-small;
      }
    }
  }

  &:checked ~ .navigation__icon .navicon {
    background: transparent;
  }

  &:checked ~ .navigation__icon .navicon:before {
    transform: rotate(-45deg);
  }

  &:checked ~ .navigation__icon .navicon:after {
    transform: rotate(45deg);
  }

  &:checked ~ .navigation__icon:not(.steps) .navicon:before,
  &:checked ~ .navigation__icon:not(.steps) .navicon:after {
    top: 0;
  }
}

.navigation__button {
  @extend .navigation__dropdown;


  & ~ .navigation__icon .navicon {
    background: transparent;
  }

  & ~ .navigation__icon .navicon:before {
    transform: rotate(-45deg);
  }

  & ~ .navigation__icon .navicon:after {
    transform: rotate(45deg);
  }

  & ~ .navigation__icon:not(.steps) .navicon:before,
  & ~ .navigation__icon:not(.steps) .navicon:after {
    top: 0;
  }
}
