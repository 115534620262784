.login {

}

.login__logo {
  margin: 55px auto $g-spacing-medium-double auto;
  width: 135px;
  height: 55px;

  background-color: $background-color;

  @include background-2x('../img/logo', 'png', contain, 50%);
}

.login__form {
  width: $g-max-width * 0.85;
  padding: $g-spacing-medium 0;
  margin: 0 auto;
  background-color: $gray;
  max-width: 308px;

  @include screen--max(340) {
    max-width: 273px;
  }

  &--darkblue {
    background-color: $darkblue;
  }
}

.login__form-group {
  padding: $g-spacing-medium 0;
}

.login__demo {
  @extend .login__form;
  padding: $g-spacing-medium 0;
  background-color: transparent;
  text-align: center;
}
