@mixin phone {
  @media (max-width: #{map-get($g-breakpoints, tablet) - 1em}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{map-get($g-breakpoints, tablet)}) and (max-width: #{map-get($g-breakpoints, desktop) - 1em}) {
    @content;
  }
}

@mixin tablet--up {
  @media (min-width: #{map-get($g-breakpoints, tablet) - 0em}) {
    @content;
  }
}

@mixin tablet--down {
  @media (max-width: #{map-get($g-breakpoints, desktop) - 1em}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{map-get($g-breakpoints, desktop) - 0em}) {
    @content;
  }
}

// Custom sizes
@mixin screen--max($screen) {
  @media (max-width: ($screen - 1) + 'px') {
    @content;
  }
}

@mixin screen--maxH($screen) {
  @media (max-height: ($screen - 1) + 'px') {
    @content;
  }
}