.button {

  @extend .input;

  $lighten-percent: 10%;

  -webkit-appearance: none;
  user-select: none;

  background-color: $g-color-base;
  color: $white;

  border: 0;

  box-shadow: $shadow-regular;

  padding: 0 $g-spacing-medium;

  font-size: $font--size--4;

  transition: background-color $g-animation-time-fast, color $g-animation-time-fast, box-shadow $g-animation-time-fast;

  &:first-child {
    margin-top: 0;
  }

  &:active {
    cursor: pointer;
    background-color: lighten($g-color-base, $lighten-percent);
    box-shadow: $shadow-light;
  }

  &#{&}--green {
    background-color: $green;

    &:active {
      background-color: lighten($green, $lighten-percent);
    }
  }

  &#{&}--blue {
    background-color: $blue;

    &:active {
      background-color: lighten($blue, $lighten-percent);
    }
  }

  &#{&}--red {
    background-color: $red;

    &:active {
      background-color: lighten($red, $lighten-percent);
    }
  }

  &#{&}--text {
    background-color: transparent;
    color: $blue;
    box-shadow: 0 1px 3px 0 rgba($black, 0);
    text-align: left;
    padding-left: 0;
  }

  &#{&}--image {
    background-size: contain;
    padding: $g-spacing-medium;
    background-position: 50% 50%;
    width: 55px;
    height: 55px;
    float: initial;
  }

}


$surrounding-total-space: $g-spacing-smaller * 2;

.button-pane {
  @extend .navigation__body;

  box-shadow: none;

  overflow: hidden;
  height: 0;
  transition: height 0.2s;

  &#{&}--active {
    height: $navigation-height + $surrounding-total-space;
    box-shadow: inset 0 -3px 3px rgba($black, .12);
  }

  &#{&}--overflow-visible {
    overflow: visible;
  }
}

.button-pane__search-pane {
  &.button-pane--active {
    height: (2 * $interaction-element-height) + $g-spacing-small + $surrounding-total-space
  }

  .input, .select {
    margin-left: 0;
  }
}

.loading {

  $lighten-percent: 0.8;
  $lighten-percent-text: 0.4;

  .button {
    box-shadow: 0 1px 3px 0 rgba($black, 0);
    pointer-events: none;
  }

  .button--green {
    background-color: transparentize($green, $lighten-percent);
    color: transparentize($white, $lighten-percent-text)
  }

  .button--blue {
    background-color: transparentize($blue, $lighten-percent);
    color: transparentize($white, $lighten-percent-text)
  }

  .button--red {
    background-color: transparentize($red, $lighten-percent);
    color: transparentize($white, $lighten-percent-text)
  }

  .navigation__icon--saveissue {
    pointer-events: none;
    opacity: 0.2;
  }
}
