$icon-spacing--custom: ($interaction-element-height - $icon--tiny) / 2;

$field-margin-simple: 5px;


input.complexField--error[type="search"] ~ input[class*="--label"],
input.complexField--error[type="hidden"] ~ ul {
  border: 1px solid $red ! important;
}

.input {
  height: $interaction-element-height;
  line-height: $interaction-element-height;

  margin: $g-spacing-tiny $g-spacing-medium;
  border: 0;
  border-radius: $radius-sml !important; // Safari first evaluates this value before it is normalized back to radius 0px

  input {
    @extend .input;

    user-select: text; /* prevent copy paste, to allow, change 'none' to 'text' */

    border-color: $gray;

    background-color: $white;
    color: #333333;
    padding: 0 $g-spacing-medium;
    margin: $g-spacing-tiny 0;

    font-size: $font--size--4;

    box-shadow: $shadow-regular;
  }

  &:first-child > input {
    margin-top: 0;
  }

  &#{&}--small {
    width: $g-max-width * 0.85 - 32;
    max-width: 275px;

    @include screen--max(340) {
      max-width: 240px;
    }

    height: $interaction-element-height;

    input {
      width: $g-max-width * 0.85 - 32;
      max-width: 275px;

      @include screen--max(340) {
        max-width: 240px;
      }
    }
  }

  &--hidden {
    display: none;
  }

  &#{&}--lowercase {
    text-transform: lowercase;

    input {
      text-transform: lowercase;

      &::-webkit-input-placeholder {
        text-transform: none;
      }
      &::placeholder {
        text-transform: none;
      }
    }
  }
}
