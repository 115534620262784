@import "../variables";
$button-height: $interaction-element-height;

.separator {
  color: $alt-1;

  position: relative;
  display: block;
  width: 100%;
  pointer-events: none;

  clear: both;

  &:before {
    border-top: 1px solid $alt-1;
    content: "";
    margin: 0 auto;
    margin-right: 1px;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: $font--size--4 * 5 / 7;
    width: calc(100% - 2px);
    z-index: 1;
  }
    
    &--with-button {
        margin-bottom: $field-margin-simple;
        cursor: pointer;
        pointer-events: auto;
        text-decoration: none;
        
        &:before {
            margin-right: $button-height + 4px;
            width: calc(100% - 44px);
        }
    }
}

.separator__title {
  background-color: $white; // Note that this also includes whitespaces only (empty title)...
  z-index: 2;
  position: relative;
  padding-right: $g-spacing-tiny;

  &:empty {

    // ... but whitespace should not be visible
    background-color: transparent;
  }
}
