@import "../sharedConfig.json";
$g-breakpoints: $breakpoint;

// -------------------------------------
//    variables
// -------------------------------------

// -- max-page-width -------------------
$g-max-width: 60em;
$g-max-width-narrow: 36em;

// -- document font declarations -------
$doc-font-size: 14px;

// -- branding -------------------------
$g-animation-time-fast: .137s;
$g-animation-time-normal: .277s;

$g-color-base: #480c11;
$g-color-comp: #D80450;
$g-tertiary-color: #83BF00;
$alt-1: #777777;
$alt-2: #CCCCCC;
$white: #FFFFFF;
$black: #000000;
$gray: #454545;

// -- TOM brand colors -----------------
$green: rgba(76, 133, 26, 1);   //#4C851A
$light-green: rgba(205, 221, 185, 1);
$red: rgba(225, 37, 51, 1); //#E12533
$blue: rgba(39, 128, 200, 1); //#288CC8
$light-blue: transparentize($blue, 0.76);
$lighter-blue: transparentize($blue, 0.82);
$darkblue: rgba(25, 44, 75, 1);
$white: rgba(255, 255, 255, 1);

$bg-white: #ffffff;
$bg-alt-1: #f5f5f5;
$bg-alt-2: #e6e6e6;
$bg-gray: #cccccc;

// -- typography -----------------------
$g-body-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
$g-header-font: $g-body-font;

// -- links ----------------------------
$link-color: #36367f;
$link-color-hover: darken($link-color, 10);
$link-color-visited: $link-color-hover;
$link-color-focus: darken($link-color-hover, 10);

// -- errors & info --------------------
$success-color: #5CB85C;
$info-color: #5BC0DE;
$warning-color: #F0AD4E;
$danger-color: #D9534F;

// -- labels, copy-classes, alerts -----
$default-color: #999999;
$g-color-muted: #F3F3F3;
$primary-color: #428BCA;

// --- selection colours ---------------
$selection: $link-color;
$selection-text: #FFF;

// -- button colors --------------------
$button-color: $white;
$button-bg-color: $g-color-base;

// -- Border Radius --------------------
$radius-sml: 3px;
$radius-med: 5px;
$radius-lrg: 9px;

// -- shadows -----------------------------
$shadow-regular: 0 1px 3px 0 rgba($black, .45);
$shadow-dark: 0 1px 3px 0 rgba($black, .76);
$shadow-light: 0 1px 3px 0 rgba($black, .15);

// -- transitions -----------------------------
$container-slide-transition-normal: left .5s ease-in-out;
$container-slide-transition-fast: left .3s ease-out;

// -- spacing -----------------------------
$g-spacing-medium: 16px;
$g-spacing-tiny: $g-spacing-medium * 1/4;
$g-spacing-smaller: $g-spacing-medium * 2/4;
$g-spacing-small: $g-spacing-medium * 3/4;

$g-spacing-regular: $g-spacing-medium + $g-spacing-medium * 1/4;
$g-spacing-large: $g-spacing-medium + $g-spacing-medium * 2/4;
$g-spacing-extra-large: $g-spacing-medium + $g-spacing-medium * 3/4;
$g-spacing-medium-double: $g-spacing-medium * 2;

$footer-height: 50px;

// -- navigation --
$navigation-height: 55px;
$interaction-element-height: 40px;
$navigation-height-small: $navigation-height * 0.8;
$interaction-element-height-small: $interaction-element-height * 0.75;

// -- font size --
$font--size--1: 1.67em;
$font--size--2: 1.3em;
$font--size--3: 1.1em;
$font--size--4: 1em;
$font--size--5: .83em;
$font--size--6: .67em;
$font--size--7: 1em;

$font--line-height--medium: 1.2em;

// -- icons --
$icon--medium: 32px;
$icon--tiny: $icon--medium * 2/4;
$icon--small: $icon--medium * 3/4;

$icon-spacing--medium: $icon--medium / 2;
$icon-spacing--tiny: $icon--tiny / 2;
$icon-spacing--small: $icon--small / 2;

// -- assets --
$asset-column-count: 4;
$asset-border--width: 3px;
$asset-column-width: calc(100% / #{$asset-column-count});
