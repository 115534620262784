.error-box {
  background-color: $red;
  color: $white;
  margin: 0 auto 10px auto;
  max-width: 308px;
  padding: 16px 36px 16px 16px;
  position: relative;
  width: 51em;

  &.error-box--hidden {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    right: $g-spacing-regular;
    top: 0;
    width: $icon--tiny;
    height: 100%;
    @include background-2x('../img/UI.iconset/small/close', 'png', contain, 100%);
  }
}

.login > .notification {
  top: 0;
  height: 0;
  width: 51em;
  max-width: 308px;
  margin: 0 auto;
}

