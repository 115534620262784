.container {
	
	display: none;

  min-height: 100%;
  position: relative;

  &__body {
    margin: 0;
    padding: 0;
  }

  &--hidden {
    display: none;
  }
}

.container__background {
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: $background-color;

  &--launchscreen {
    @include background-2x('../img/TOM_launchscreen', 'png', contain, 50%);
  }
}

.loading .container {
  padding-top: $g-spacing-tiny;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: 0.7em;
    text-align: center;
    width: 100%;
    height: $g-spacing-tiny;
    background-color: $lighter-blue;
    background-image: linear-gradient( -90deg, $light-blue 25%, transparent 25%, transparent 50%, $light-blue 50%, $light-blue 75%, transparent 75%, transparent );
    background-size: 50px 50px;
    animation: move 0.9s linear infinite;
  }
}

@keyframes move{
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
