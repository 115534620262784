.headline {
  &--size-1 {
    font-size: $font--size--1;
  }
  &--size-2 {
    font-size: $font--size--2;
    line-height: $font--line-height--medium;
  }
  &--size-3 {
    font-size: $font--size--3;
    line-height: $font--line-height--medium;
  }
  &--size-4 {
    font-size: $font--size--4;
    line-height: $font--line-height--medium;
  }
  &--size-5 {
    font-size: $font--size--5;
    line-height: $font--line-height--medium;
  }
  &--size-6 {
    font-size: $font--size--6;
    line-height: $font--line-height--medium;
  }
}